import React, { lazy, Suspense } from "react";

import Loader from "components/loader/Loader";
import Layout from "../components/layout";

const Game = lazy(() => import("../components/game"));

const LessonsGame = () => {
  const isSSR = typeof window === "undefined";

  return (
    <Layout title="مراجعة" classSection="gameSection">
      <div className="game">
        {!isSSR && (
          <Suspense fallback={<Loader />}>
            <Game />
          </Suspense>
        )}
      </div>
    </Layout>
  );
};

export default LessonsGame;
